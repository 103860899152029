<template>
  <!-- 基础合成通用页面 -->
  <div style="overflow-x: hidden">
    <div class="container">
      <div class="head-bg">
        <van-image width="100vw" :src="detail.image_bg" />
        <div class="meng"></div>
      </div>

      <div class="content">
        <div class="content-item contentFirst">
          <div class="title">
            <div class="name">
              {{ detail.items_name }}
              <img :src="levelsChange(detail.levels)" alt="" />
            </div>
            <div class="num">限量领取{{ detail.total_num }}份</div>
          </div>
          <div class="cl-box">
            <div class="title"><img src="./img/stuffTitle.png" alt="" /></div>
            <div class="hero">
              <div class="hero-item" v-for="(item, index) in stuffList" :key="index">
                <div class="box1">
                  <img :src="item.fodder_img" alt="" />
                  <div class="num">{{ item.has_num }}</div>
                  <div class="item-meng" @click="heroFn" v-if="item.has_num < 1"></div>
                  <div class="neednum">需<span>1</span>个</div>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-wrapper">
            <getBtn
              :time="start_time"
              :end_time="end_time"
              :hasAll="detail.hasAll"
              @finistTime="finistTime"
              :is_merge="is_over"
              @compoundHreo="compoundHreo"
              :server_time="server_time"
            />
          </div>

          <!-- <div class="bt-words" v-if="diffTime(detail.start_time) > 0">
          拥有“<span>传说级藏品</span> ”，可在{{
            timeChange(detail.priority_start_time, 'MM月DD日HH:mm')
          }}-{{ timeChange(detail.start_time, 'HH:mm') }}优先合成
        </div> -->
        </div>

        <div class="content-item">
          <div class="sub-title">
            <div class="sub-title-box">
              <img src="./img/titleBg.png" alt="" />
              <div class="titleText">{{ detail.items_name }}</div>
            </div>
          </div>
          <div class="sub-content" style="text-align: center">
            <div>{{ detail.description }}</div>
          </div>
        </div>
        <!-- 详情图 -->
        <!-- <div class="detailImgContianer" v-if="detail.image">
          <img v-for="(item, index) in JSON.parse(detail.image)" :src="item" :key="index" alt="" />
        </div> -->
        <div class="brigt">
          <div class="">
            <div class="t-c">
              <img class="wid237" :src="require('@/imgs/comm/icon_yehc_xllq.png')" alt="" />
            </div>

            <div class="">
              <div class="flex-bt marg36">
                <img class="wid90" :src="require('@/imgs/comm/Slice20.png')" alt="" />
                <img class="wid90" :src="require('@/imgs/comm/Slice21.png')" alt="" />
              </div>
              <div class="flex-bt">
                <img class="wid90" :src="require('@/imgs/comm/Slice22.png')" alt="" />
                <img class="wid90" :src="require('@/imgs/comm/Slice23.png')" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="parameter f-12">
          <div class="">
            <div class="title t-c">
              <img class="wid237" :src="require('@/imgs/comm/Slice16.png')" alt="" />
            </div>
            <div class="bright-item">
              <span class="c-07">商品名称</span>
              <span> {{ productDetail.items_name }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品类型</span>
              <span> {{ productDetail.type_name }}</span>
            </div>

            <div class="bright-item">
              <span class="c-07">品牌方</span>
              <span> {{ productDetail.producter }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">发行方</span>
              <span> {{ productDetail.publisher }}</span>
            </div>
            <div class="bright-item" v-if="productDetail.publish_date">
              <span class="c-07">发行时间</span>
              <span> {{ timeChange(productDetail.publish_date, 'YYYY年MM月DD日') }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品编号</span>
              <span> {{ productDetail.series_num }}</span>
            </div>
            <!-- <div class="bright-item">
            <span class="c-07">铸造数量</span>
            <span> {{ productDetail.token_num }}</span>
          </div> -->
          </div>
        </div>
      </div>
    </div>

    <PageLoading :show="loading" />
    <getPop
      :compoundPopShow="compoundPopShow"
      :detail="detail"
      :list="stuffList"
      @close="(compoundPopShow = false), finistTime()"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getuseryefanstuff } from '@/service/compound';
import getBtn from '@/components/getBtn';
import getPop from './components/getPop.vue';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { Toast } from 'vant';
import { shareHelper } from '@/helpers';
import { domUtil } from '@/utils';
import { useRoute } from 'vue-router';

export default {
  components: {
    getBtn,
    getPop,
    PageLoading
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      detail: {},
      compoundPopShow: false,
      loading: true,
      server_time: 0,
      productDetail: {},
      stuffList: [],
      start_time: null,
      end_time: null,
      is_over: false
    });

    onMounted(async () => {
      finistTime();
      const shareConf = {
        title: '看漫数藏',
        content: '由看漫APP倾力打造，藏品有限，价值无限',
        image: '',
        link: window.location.origin + '/home', // app分享链接
        url: window.location.origin + '/home' // app分享链接
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);
    });
    const heroFn = async () => {
      Toast('该材料不足，快去购买材料吧～');
    };
    const finistTime = async () => {
      const { data, server_time } = await getuseryefanstuff();
      state.detail = data.items;
      state.server_time = server_time;
      state.start_time = data.start_time;
      state.end_time = data.end_time;
      state.is_over = data.is_over;
      let hasAll = true;
      state.stuffList = data.stuff;
      for (let i in state.stuffList) {
        if (state.stuffList[i].has_num < 1) {
          hasAll = false;
        }
      }
      state.detail = { ...state.detail, hasAll };
      state.loading = false;

      data.result_items && (state.productDetail = data.result_items);
    };
    // 合成按钮
    const compoundHreo = () => {
      state.compoundPopShow = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      levelsChange,
      finistTime,
      compoundHreo,
      timeChange,
      heroFn,
      diffTime
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';

.container {
  position: relative;

  .head-bg {
    position: relative;

    .meng {
      width: 100vw;
      height: 160px;
      position: absolute;
      // top: -160px;
      bottom: 0;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
    }
  }

  .content {
    padding: 0 16px 10px;
    position: absolute;
    top: 100%;
    margin-top: -80px;
    padding-bottom: 120px;

    .content-item {
      background: #2f293f;
      padding: 34px 11px 10px;
      margin-bottom: 36px;
      position: relative;

      .sub-title {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        justify-content: center;

        // .title-img {
        //   width: 100%;
        //   height: 50px;
        // }
        .sub-title-box {
          font-size: 0px;
          position: relative;

          img {
            width: 192.5px;
            height: 36px;
          }

          .titleText {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            white-space: nowrap;
          }
        }
      }
      .sub-content {
        // margin-top: 34px;
        font-size: 12px;

        color: rgba(255, 255, 255, 0.7);
        span {
          color: rgba(255, 255, 255, 1);
          font-weight: bold;
        }
        p {
          margin: 2px 0;
        }
        div {
          margin-bottom: 10px;
          &:nth-child(1) {
            margin-bottom: 15px;
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        .name {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
        }
        img {
          width: 50px;
          margin-left: 5px;
        }
        .num {
          background: #3cdbe8;
          color: #2f293f;
          padding: 0 8px;
          line-height: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
        }
      }
      .cl-box {
        background: #1c172a;
        padding: 0 5px 10px 5px;
        margin-top: 18px;
        .title {
          text-align: center;
          font-size: 0px;
          padding: 5px 0;
          img {
            width: 237px;

            margin: 0 auto;
          }
        }
        .hero-wrapper {
          display: flex;
          justify-content: center;
        }
        .hero1 {
          padding: 0 27px;
        }
        .hero {
          display: flex;
          flex-wrap: wrap;
          font-size: 0px;
          align-items: center;
          justify-content: space-around;
          //justify-content: space-between;
          img {
            width: 70px;
          }
          .mr10 {
            margin-right: 10px;
          }
          .choose {
            border: 1px solid #5fdfd3;
            border-radius: 4px;
            background-color: #2f293f;
          }
          .nochoose {
            border: 1px solid #1c172a;
            border-radius: 4px;
          }
          // .w33 {
          //   width: 33%;
          // }
          .hero-item {
            width: 25%;
            // position: relative;

            .box1 {
              // margin-right: 10px;
              // &:nth-child(3n + 4) {
              //   margin-right: 0px;
              // }
              position: relative;
              margin: 0 auto;
              text-align: center;
            }
            .martop14 {
              margin-top: 14px;
            }
            .num {
              position: absolute;
              right: 9px;
              top: 9px;
              background: #2f293f;
              font-size: 12px;
              width: 18px;
              height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              z-index: 2;
            }
            .neednum {
              font-size: 12px;
              text-align: center;
              span {
                color: $primary;
              }
            }
            .item-meng {
              width: 69px;
              height: 73px;
              background: #2f293f;
              opacity: 0.5;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0);
              border-radius: 50%;
            }
          }
        }
      }
    }

    .contentFirst {
      margin-top: 0;
      padding-top: 16px;
    }

    .detailImgContianer {
      font-size: 0;
      margin-bottom: 26px;
      img {
        width: 100%;
      }
    }
  }
}
.brigt {
  padding: 24px 54px 38px;
  @include bis('../../imgs/comm/Slice9.png');
  margin-bottom: 24px;
  .brigt-content {
    margin-bottom: 24px;
  }
}

.f-12 {
  font-size: 12px;
}
.parameter {
  @include bis('../../imgs/comm/Slice9.png');
  padding: 10px 21px 27px;
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
}
.c-07 {
  color: rgba(255, 255, 255, 0.7);
}

.t-c {
  text-align: center;
}
.f-10 {
  font-size: 10px;
}
.f-0 {
  font-size: 0px;
}
.flex-bt {
  @include fj();
}
.wid237 {
  width: 237px;
}
.wid90 {
  width: 90px;
  height: 100px;
}
.marg36 {
  margin-bottom: 26px;
}
.bt-words {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 600;
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 11px 0;
}
</style>
  