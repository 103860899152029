import { http } from '@/api';

// 合成藏品v1
export function mergeitems(params) {
  return http.post('/v1/items/mergeitemsv2', params);
}
// export function mergeitemsv2(params) {
//   return http.post('/v1/items/mergeitemsv2', params);
// }
// 获取合成材料
export function getstuff(params) {
  return http.get('/v1/items/getstuff', params);
}

// 合成接口信息V2
export function mergeitemstwo(params) {
  return http.post('/v1/items/mergeitemstwo', params);
}

// 七夕合成接口素材接口
export function qiximergestuff(params) {
  return http.get('/v1/items/qiximergestuff', params);
}

// 七夕活动合成接口
export function qiximergeitem(params) {
  return http.post('/v1/items/qiximergeitem', params);
}

// 诛天至尊合成素材接口
export function zhutianwanyustuff(params) {
  return http.get('/v1/items/zhutianwanyustuff', params);
}

// items - 诛天至尊合成接口
export function zhutianwanyumergeitem(params) {
  return http.post('/v1/items/zhutianwanyumergeitem', params);
}

// items - 犼/灵鸟的合成素材接口
export function houorlingniaostuff(params) {
  return http.get('/v1/items/houorlingniaostuff', params);
}

// items - 犼/灵鸟合成接口
export function houorlingniaomergeitem(params) {
  return http.post('/v1/items/houorlingniaomergeitem', params);
}

// items - 凌霄棍合成素材接口
export function lingxiaogunstuff(params) {
  return http.get('/v1/items/lingxiaogunstuff', params);
}
// items - 凌霄棍合成
export function lingxiaogunmergeitem(params) {
  return http.post('/v1/items/lingxiaogunmergeitem', params);
}

// 乌骓合成素材接口
export function wuzhuistuff(params) {
  return http.get('/v2/merge/wuzhuistuff', params);
}

// 乌骓合成接口
export function wuzhuimergeitem(params) {
  return http.post('/v2/merge/wuzhuimergeitem', params);
}

// 乌骓合成素材接口
export function putiantongqingstuff(params) {
  return http.get('/v2/merge/putiantongqingstuff', params);
}

// 乌骓合成接口
export function putiantongqingmergeitem(params) {
  return http.post('/v2/merge/putiantongqingmergeitem', params);
}

// 合成通用素材接口信息
export function commonstuff(params) {
  return http.get('/v2/merge/commonstuff', params);
}

// 通用数据合成接口
export function commonmergeitem(params) {
  return http.post('/v2/merge/commonmergeitem', params);
}

// 获取合成列表
export function getmergelist(params) {
  return http.get('/v2/merge/getmergelist', params);
}

// 获取叶凡领取接口材料接口
export function getuseryefanstuff(params) {
  return http.get('/v2/yefan/getuseryefanstuff', params);
}

export function getyefan(params) {
  return http.post('/v2/yefan/getyefan', params);
}

// 获取星空霸体术秘典合成材料接口
export function getxingkongbatistuff(params) {
  return http.get('/v2/merge/getcommergestuff', params);
}

// 星空霸体术秘典
export function xingkongbatimerge(params) {
  return http.post('/v2/merge/comatwillmerge', params);
}


