/*
 * @Author: caishui
 * @Date: 2021-07-26
 * @LastEditors  : VSCode
 * @LastEditTime : 2021-07-26
 * @Description: 由vue2版本项目的mixin迁移
 */
/**
 * 分享mixin
 * 设置app内右上角分享 微信分享
 */

import { api } from '@/api';
import { shareUtil, clientUtil } from '@/utils';

class ShareHelper {
  constructor() {
    this.shareConf = {};
  }
  init(conf) {
    this.shareConf = conf;
    if (JSON.stringify(this.shareConf) !== '{}') {
      this.setShareInfo();
    }
  }
  getShareConf() {
    return this.shareConf;
  }
  /* eslint-disable */
  setShareInfo() {
    window.shareCallback = this.shareConf.callback;
    // 设置微信分享
    if (clientUtil.getClientInfo().isWX) {
      this.setWxShare(this.shareConf.callback);
    }
    // 设置app分享
    this.shareConf = {
      ...this.shareConf,
      ...{
        callback: 'shareCallback'
      }
    };
    shareUtil.setShareConfFn(this.shareConf);
  }

  /* eslint-disable */
  /**
   *
   * @param {fn} callback 分享成功回调
   * @memberof ShareMixin
   */
  setWxShare(callback) {
    let params = {
      url: location.href.split('#')[0].toString()
    };
    api.setWxShare(params).then(res => {
      let data = res;
      if (data.status === 0) {
        let dataObj = {
          // debug: true,
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareQZone'
          ]
        };
        Object.assign(dataObj, data.data);
        wx.config(dataObj);

        this.shareConf.desc = this.shareConf.content;
        this.shareConf.imgUrl = this.shareConf.image;
        this.shareConf.success = callback;
        delete this.shareConf.link;
        wx.ready(() => {
          wx.onMenuShareAppMessage(this.shareConf);
          wx.onMenuShareTimeline(this.shareConf);
          wx.onMenuShareQQ(this.shareConf);
          wx.onMenuShareQZone(this.shareConf);
        });
      }
    });
  }
}

export default new ShareHelper();